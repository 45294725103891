<template>
  <div class="mb-3 mt-5 pt-3">
    <div class="row">
      <div
        :class="{
          [$style.card__register]: true,
        }"
      >
        <div
          :class="{
            [$style.card__register__image]: true,
          }"
        >
          <img
            src="https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482"
            width="131"
            height="63"
          />
        </div>

        <div class="d-flex justify-content-center align-items-center pbz-font subtitle-md-medium mt-5 mb-3" style="color: #1A1A1A !important">
          {{ $t('auth.needVerification') }}
        </div>

        <div class="d-flex text-center justify-content-center align-items-center pbz-font body-md-regular my-3" style="color: #999999 !important">
          Kami telah mengirimkan link untuk Anda melakukan verifikasi akun ke alamat email
        </div>

        <div
          class="pbz-font body-md-medium mt-3 d-flex justify-content-center align-items-center"
          style="color: #1A1A1A"
        >
          {{ email }}
        </div>

        <div
          :class="{
            [$style.changePhoneNumber]: true,
          }"
          class="mb-2 mt-5"
          @click.prevent="() => $router.push({ path: '/auth/register' })"
        >
          <div class="pbz-font button-md-medium" style="color: #0559CB !important">
            {{ $t('auth.changeEmail') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    email () {
      return this.$store.state.user.email
    },
  },
}
</script>

<style lang="scss" module>
@import "@/components/cleanui/system/Auth/style.module.scss";
</style>
