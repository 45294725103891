var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-3 mt-5 pt-3"},[_c('div',{staticClass:"row"},[_c('div',{class:{
        [_vm.$style.card__register]: true,
      }},[_c('div',{class:{
          [_vm.$style.card__register__image]: true,
        }},[_c('img',{attrs:{"src":"https://ik.imagekit.io/powerbiz/img/admin-saas/Logo.png?updatedAt=1631759657482","width":"131","height":"63"}})]),_c('div',{staticClass:"d-flex justify-content-center align-items-center pbz-font subtitle-md-medium mt-5 mb-3",staticStyle:{"color":"#1A1A1A !important"}},[_vm._v(" "+_vm._s(_vm.$t('auth.needVerification'))+" ")]),_c('div',{staticClass:"d-flex text-center justify-content-center align-items-center pbz-font body-md-regular my-3",staticStyle:{"color":"#999999 !important"}},[_vm._v(" Kami telah mengirimkan link untuk Anda melakukan verifikasi akun ke alamat email ")]),_c('div',{staticClass:"pbz-font body-md-medium mt-3 d-flex justify-content-center align-items-center",staticStyle:{"color":"#1A1A1A"}},[_vm._v(" "+_vm._s(_vm.email)+" ")]),_c('div',{staticClass:"mb-2 mt-5",class:{
          [_vm.$style.changePhoneNumber]: true,
        },on:{"click":function($event){$event.preventDefault();return (() => _vm.$router.push({ path: '/auth/register' })).apply(null, arguments)}}},[_c('div',{staticClass:"pbz-font button-md-medium",staticStyle:{"color":"#0559CB !important"}},[_vm._v(" "+_vm._s(_vm.$t('auth.changeEmail'))+" ")])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }